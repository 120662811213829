<template>
	<w-layout>
		<w-flex>
			<w-text-input
				v-model="application.subtitle"
				auto-focus
				color="primary"
				:counter="rules.subtitleLength"
				:label="$t('mobileapp.fields.subtitle')"
				:rules="[
					value => {
						let result = true
						if (!value || value.trim().length <= 0) {
							result = $t('mobileapp.rules.field.required')
						}
						return result
					},
					value => {
						let result = true
						if (!value || value.trim().length > rules.subtitleLength) {
							result = $tc('mobileapp.rules.field.maxlen', 0, { fieldname: $t('mobileapp.fields.subtitle').toLowerCase(), max: rules.subtitleLength })
						}
						return result
					},
					value => {
						let result = true
						if (!value || (value.length > 0 && value == application.title)) {
							result = $tc('mobileapp.rules.field.equal', 0, {
								fieldname1: $t('mobileapp.fields.subtitle').toLowerCase(),
								fieldname2: $t('mobileapp.fields.title').toLowerCase(),
							})
						}
						return result
					}
				]"
				@input="application = application"
			></w-text-input>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppSubtitle',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			rules: {
				subtitleLength: 30
			}
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		}
	}
}
</script>
